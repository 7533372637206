.messagingSection {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  background-color: lightgrey;
}

.applicationPane {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}
