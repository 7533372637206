.messageBox {
  display: flex;
  flex: 1;
  min-height: 0;
}

.messages {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-wrap: break-word;
  padding: 0 15px;
  display: flex;
  flex-direction: column-reverse;
}
