.messageImage {
  max-height: 500px;
  min-height: 200px;
  display: block;
}

.textMessage {
  padding: 1em 0;
  margin: 0 0 0 0;
}

.unLoadedImage {
  display: none;
}
